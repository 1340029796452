/**
 * Local storage key
 */
export const LOCAL_STORAGE_KEY: string = 'workplace-auth';

export const MediaType = {
  PHOTO: 1,
  VIDEO: 3,
  AUDIO: 2,
  ALBUM: 4,
  DOCUMENT: 5
  // PEDIGREE: 6,
  // VET_UPDATES: 7,
  // VACCINATIONS: 8
};

/**
 * Account type list
 */
export const ACCOUNT_TYPE_LIST: any[] = [
  {
    id: 1,
    name: 'Employee'
  },
  {
    id: 2,
    name: 'Contractor'
  },
  {
    id: 3,
    name: 'Visitor'
  },
  {
    id: 4,
    name: 'Delivery'
  }
];

export const ACCOUNT_TYPE_EMPLOYEE: number = 1;
export const ACCOUNT_TYPE_CONTRACTOR: number = 2;
export const ACCOUNT_TYPE_VISITOR: number = 3;
export const ACCOUNT_TYPE_DELIVERY: number = 4;
export const GOOGLE_ANALYTICS_KEY = 'UA-185724829-2'; // for testing sites
